import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core/';
import Button from '@material-ui/core/Button/Button';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField/TextField';
import Typography from '@material-ui/core/Typography/Typography';
import SvgIcon from '@material-ui/icons/CommentOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useTranslations } from 'next-intl';
import { NextRouter, useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { capitalizeString } from '../components/utils';
import logo from '../public/images/rikutec-logo.svg';
import { loginReqIdAction, removeTokenAction, resolvedTokenAction } from '../redux/actions/securityActionCreator';
import { wrapper } from '../redux/store';
import getRequestIdGenerator from '../services/requestIdGenerator';
import Security from '../services/security';
import {
    AFTER_LOGIN_REDIRECT_TO,
    AVATARS,
    GROUP_METADATA_AVATAR_TYPES,
    MAIN_PAGE,
    RIKUTEC_FACTURATION_ID, RIKUTEC_TEST_FACTURATION_ID,
    ROOT_PAGE,
    STATE_TO_CHANGE,
    TOKEN_WITH_ROLES,
} from '../services/utils/CONST';
import { getLocale } from '../services/utils/get-translates';
import { routeWithParamAs } from '../services/utils/route';
import { GlobalState } from '../redux/reducers';
import { resolvedTokenWithRolesActionCreator } from '../redux/actions/resourceActionCreator';
import { crudGetOneReqIdAction } from '../redux/actions/fetchActionCreator';
import requestIdGenerator from '../services/requestIdGenerator';
import { getResourceURIFROMAnotherURIWithoutId } from '../services/utils/ids';
import { Avatar, MC } from '../services/utils/types';
import { getMC } from '../redux/selectors/mc';
import { getAvatar } from '../redux/selectors/avatar';
import { Backdrop, styled } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    text: {
        fontSize: 24,
        marginBottom: 10,
    },
    logo: {
        textAlign: 'center',
    },
    input: {
        marginBottom: 10,
    },
    buttonsBlock: {
        marginTop: 20,
    },
    button: {
        marginLeft: 20,
    },
}));

const CustomBackdrop = styled(Backdrop)({
    zIndex: 3000,
});

interface Props {}

const getRedirectToRoute = (router: NextRouter) =>
    AFTER_LOGIN_REDIRECT_TO in router.query ? router.query[AFTER_LOGIN_REDIRECT_TO] : MAIN_PAGE;

const Login = ({}: Props) => {
    const t = useTranslations('login');
    const tCommon = useTranslations('common');
    const dispatch = useDispatch();
    const classes = useStyles();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<any>(false);
    const router: NextRouter = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const tokenData = useSelector((state: GlobalState) => state.user.tokenData);
    const mc: MC | null = useSelector((state: GlobalState) => getMC(state.resources.mcUriFromUrl)(state));
    const [loadingLogin, setLoadingLogin] = useState(false);

    const handleChangeUsername = ({ target: { value } }: any) => {
        setUsername(value);
    };

    const handleChangePassword = ({ target: { value } }: any) => {
        setPassword(value);
    };

    useEffect(() => {
        if (!mc) routeWithParamAs(router, `${ROOT_PAGE}?${qs.stringify(router.query)}`);
    }, [mc]);

    const redirectBack = () => {
        const object = { ...router.query };
        delete object[AFTER_LOGIN_REDIRECT_TO];
        delete object[STATE_TO_CHANGE];
        routeWithParamAs(router, `${getRedirectToRoute(router)}?${qs.stringify(object)}`);
    };

    const redirectAfterLogin = () => {
        const object = { ...router.query };
        delete object[AFTER_LOGIN_REDIRECT_TO];
        routeWithParamAs(router, `${getRedirectToRoute(router)}?${qs.stringify(object)}`);
    };

    useEffect(() => {
        if (
            tokenData &&
            tokenData.payload.company_id_facturation &&
            (tokenData.payload.company_id_facturation === RIKUTEC_FACTURATION_ID || tokenData.payload.company_id_facturation === RIKUTEC_TEST_FACTURATION_ID) &&
            !tokenData.payload.is_anonymous
        ) {
            redirectAfterLogin();
        }

        if (
            tokenData &&
            tokenData.payload.company_id_facturation &&
            tokenData.payload.company_id_facturation !== RIKUTEC_FACTURATION_ID &&
            tokenData.payload.company_id_facturation !== RIKUTEC_TEST_FACTURATION_ID
        ) {
            dispatch(removeTokenAction.fn());

            enqueueSnackbar(t('noAccessWrongCompany'), {
                variant: 'error',
            });
        }
    }, [tokenData]);

    const getTokenWithRolesRequest = (uri: string) => {
        dispatch(
            crudGetOneReqIdAction.fn(
                requestIdGenerator().gen(),
                AVATARS,
                getResourceURIFROMAnotherURIWithoutId(uri, TOKEN_WITH_ROLES),
                {
                    onSuccess: async ({ data }: { data: any }) => {
                        const { token, refresh_token: refreshToken } = data;
                        dispatch(resolvedTokenWithRolesActionCreator.fn({ token, refreshToken }));
                    },
                },
            ),
        );
    };

    const handleLogin = () => {
        setLoadingLogin(true);

        dispatch(
            loginReqIdAction.fn(getRequestIdGenerator().gen(), username, password, {
                // @ts-ignore
                onSuccess: (payload: { token: string; refresh_token: string }) => {
                    dispatch(resolvedTokenAction.fn(payload.token, payload.refresh_token));
                    if (mc) getTokenWithRolesRequest(mc['@id']);
                },
                onFailure: (error) => {
                    console.error(error);
                    enqueueSnackbar(t('noAccessWrongCompany'), {
                        variant: 'error',
                    });
                    setLoadingLogin(false);
                },
            }),
        );
    };

    const handleClickShowPassword = () => {
        setShowPassword((previousValue: boolean) => setShowPassword(!previousValue));
    };

    return (
        <Fragment>
            <Grid container className={classes.root} direction={'column'} alignContent={'stretch'}>
                <Grid item>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            <SvgIcon
                                component={logo}
                                viewBox='0 -10 236 80'
                                style={{ width: 200, height: 100 }}
                                className={classes.logo}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Typography className={classes.text} align={'center'}>
                        {String(t('login'))}
                    </Typography>
                </Grid>

                <Grid item>
                    <TextField
                        fullWidth
                        label={capitalizeString(String(t('usernameFieldPlaceholder')))}
                        value={username}
                        onChange={handleChangeUsername}
                        data-testid='username'
                        className={classes.input}
                    />
                </Grid>

                <FormControl>
                    <InputLabel htmlFor='standard-adornment-password'>
                        {capitalizeString(String(t('passwordFieldPlaceholder')))}
                    </InputLabel>
                    <Input
                        id='standard-adornment-password'
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handleChangePassword}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton onClick={handleClickShowPassword}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <Grid item>
                    <Grid container justifyContent={'flex-end'} className={classes.buttonsBlock}>
                        <Grid item>
                            <Button
                                data-testid='return'
                                color={'primary'}
                                className={classes.button}
                                onClick={redirectBack}
                            >
                                {tCommon('buttons.return')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                data-testid='login'
                                variant={'contained'}
                                color={'primary'}
                                className={classes.button}
                                onClick={handleLogin}
                            >
                                {tCommon('buttons.login')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <style jsx global>
                    {`
                        body {
                            background-color: white !important;
                        }
                    `}
                </style>
            </Grid>

            {loadingLogin && (
                <CustomBackdrop open={loadingLogin}>
                    <CircularProgress color='primary' />
                </CustomBackdrop>
            )}
        </Fragment>
    );
};

export default Login;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
    await Security.handleSagaTaskInBackend(store);

    return {
        props: {
            messages: {
                ...require(`../messages/common/${getLocale(context)}.json`),
                ...require(`../messages/login/${getLocale(context)}.json`),
            },
        },
    };
});
